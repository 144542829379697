<template>
    <div class="review d-flex mt-4">
        <div
            v-if="!isMobile"
            class="avatar"
        >
            <img
                class="avatar-img"
                :src="review.user_avatar ? review.user_avatar : '/images/admin.webp'"
                alt="avatar"
                width="32"
                height="32"
            >
        </div>
        <div class="review-content">
            <div class="heading d-flex">
                <img
                    v-if="isMobile"
                    class="avatar-img"
                    :src="review.user_avatar ? review.user_avatar : '/images/admin.webp'"
                    alt="avatar"
                    width="32"
                    height="32"
                >
                <p class="sbold">
                    {{ review.user_name }}
                </p>
            </div>
            <div class="rating mt-2">
                <rating-stars :rating="{ value: review.rating, counter: null }" />
            </div>
            <p class="sm date mt-2">
                {{ review.created_at }}
            </p>
            <p class="sbold mt-2">
                {{ message }}
            </p>
        </div>
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';

    import RatingStars from '@/components/product/RatingStars';
    export default {
        components: { RatingStars },
        name: 'ReviewItem',
        props: {
            review: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        computed: {
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
            message() {
                return this.review.message[i18n.locale];
            },
        },
    };
</script>
<style scoped lang="scss">
.review {
  gap: 18px;
  .avatar-img {
    border-radius: 32px;
  }
  .review-content {
    .heading {
      p {
        &:first-of-type {
          color: $text;
        }
      }
    }
    .date {
      font-size: 11px;
      line-height: 13px;
      color: $gray-dark;
    }
    .rating {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
    p {
      color: $text;
    }
  }
}
</style>
