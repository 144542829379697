<template>
    <div class="review-slider">
        <div
            v-for="rating in ratings"
            :key="`slide-${rating.stars}`"
            class="review-slider-items d-flex"
        >
            <div class="star-nr">
                <p class="sm-sbold">
                    {{ rating.stars }} star
                </p>
            </div>
            <div class="review-slide">
                <div
                    class="inner"
                    :style="{ width: rating.percentage + '%' }"
                />
            </div>
            <div class="review-count">
                <p class="sm-sbold">
                    ({{ rating.counter }})
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ReviewSlider',
        props: {
            ratings: {
                type: Array,
                required: true,
            },
        },
    };
</script>

<style scoped lang="scss">
.review-slider {
  .review-slider-items {
    align-items: center;
    gap: 12px;
    margin-bottom: 4px;
    .star-nr {
      p {
        color: $text;
      }
    }
    .review-slide {
      position: relative;
      flex: 1;
      width: 100%;
      background-color: $gray-light;
      height: 12px;
      border-radius: 8px;
      overflow: hidden;
      .inner {
        position: absolute;
        width: 100%;
        height: 12px;
        background-color: $secondary;
        border-radius: 8px;
      }
    }
    .review-count {
      p {
        color: $text;
      }
    }
  }
}
</style>
