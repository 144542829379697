<template>
    <b-row
        id="reviews"
        class="reviews mt-40"
    >
        <b-col
            v-if="existData"
            cols="12"
        >
            <h5 class="title">
                {{ $t('page.product.reviewsHeading') }}
            </h5>
            <div
                v-if="reviews"
                class="d-flex mt-3"
            >
                <div class="text-center average">
                    <span class="avg">{{ reviews.avg }}</span>
                    <rating-stars :rating="{ value: reviews.avg, counter: null }" />
                    <p>{{ totalReviews }}</p>
                </div>
                <div class="rev-slides">
                    <review-slider :ratings="reviews.ratings" />
                </div>
                <div :class="{ transparent: !reviews.canAddReview, 'add-review': true }">
                    <h5>{{ $t('page.product.addReviewLabel') }}</h5>
                    <p class="sbold">
                        {{ $t('page.product.addReviewText') }}
                    </p>
                    <div class="stars">
                        <star-icon
                            v-for="index in 5"
                            :key="index"
                            @click="openRatingModal(index)"
                        />
                    </div>
                    <b-button
                        variant="primary"
                        class="fill sm"
                        @click="openRatingModal()"
                    >
                        {{ reviews.reviewAddedByUser ? $t('page.product.editReviewButton'): $t('page.product.addReviewButton') }}
                    </b-button>
                </div>
            </div>
            <review-item
                v-for="review in reviews.elements"
                :key="`review-${reviews.elements.indexOf(review)}`"
                :review="review"
            />
            <review-pagination
                v-if="multiplePages"
                :page="reviews.page"
                type="reviews"
            />
        </b-col>
        <product-reviews-loader v-if="loading && !existData" />
        <rating-modal
            v-if="showModal"
            :rating="rating"
            :product-id="product.id"
            :is-edit="reviews.reviewAddedByUser"
        />
    </b-row>
</template>

<script>
    import StarIcon from 'vue-feather-icons/icons/StarIcon';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    import ProductReviewsLoader from '@/components/loaders/product/ProductReviewsLoader';
    import RatingStars from '@/components/product/RatingStars';

    import ReviewPagination from './ProductPagination';
    import ReviewItem from './ReviewItem';
    import ReviewSlider from './ReviewSlider';

    const RatingModal = () => import('@/components/modals/RatingModal');

    export default {
        components: {
            ReviewItem,
            RatingStars,
            ReviewSlider,
            StarIcon,
            ReviewPagination,
            ProductReviewsLoader,
            RatingModal,
        },
        name: 'ReviewStatistics',
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
            product: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                rating: null,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            ...mapState('product', ['reviews', 'loading']),
            totalReviews() {
                let number= this.reviews.page.total;
                return number > 1
                    ? this.$t('page.product.multipleReviewText', { number })
                    : this.$t('page.product.singleReviewText', { number });
            },
            multiplePages() {
                return Object.keys(this.reviews).length > 0
                    ? this.reviews.page.lastPage > 1
                    : false;
            },
            existData() {
                return Object.keys(this.reviews).length !== 0;
            },
        },
        methods: {
            ...mapActions('product', ['getReviews']),
            ...mapMutations('product', ['setModal']),
            ...mapMutations('general', { setGeneralModal: 'setModal' }),
            openRatingModal(index = null) {
                if (!this.isLogged) {
                    this.setGeneralModal({
                        show: true,
                        type: 'login',
                    });
                    return;
                }
                this.rating = index;
                this.setModal({
                    show: true,
                    type: 'rating',
                });
            },
        },
        watch: {
            product: {
                handler() {
                    this.getReviews();
                },
                deep: true,
            },
        },
    };
</script>
<style scoped lang="scss">
.reviews {
  .d-flex {
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    h5 {
      &.title {
        margin-bottom: 20px;
        color: $black;
      }
    }
    .avg {
      color: $gray;
      margin-top: 14px;
      font-size: 49px;
      font-weight: 600;
      @include media-breakpoint-down(lg) {
        font-size: 49px;
      }
    }
    .average {
      @include media-breakpoint-down(md) {
        width: 80px;
      }
      p {
        font-size: 12px;
        color: $text;
        line-height: 18px;
      }
      .rating {
        justify-content: center;
      }
    }
    .rev-slides {
      flex: 1;
      padding-left: 48px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .add-review {
      padding-left: 48px;
      width: 40%;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 0;
        margin-top: 24px;
      }
      h5 {
        color: $text;
        font-size: 22px;
        font-weight: 700;
        @include media-breakpoint-down(lg) {
          font-size: 18px;
        }
      }
      p {
        margin-top: 4px;
        color: $gray-dark;
      }
      .stars {
        svg {
          stroke: $gray;
          margin-top: 8px;
        }
        @include media-breakpoint-up(md) {
          &:hover {
            > svg {
              stroke: $secondary;
              cursor: pointer;
              &:hover ~ svg {
                stroke: $gray;
              }
            }
          }
        }
      }

      button {
        margin-top: 8px;
      }
    }
  }
}
</style>
